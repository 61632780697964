import React  from "react";
import img1 from '../assets/media/mustafa_ramzan.png'
import img2 from '../assets/media/kashif_riaz.png'

function Team() {




  return (
    <main className=" py-14">
      <div data-aos="fade-up" className="px-4">
        <h1 className="font-inter-serif font-semibold  text-[36px] md:text-[48px] text-center text-black my-10" >Our Team </h1>
      </div>

      <div className="max-w-screen-2xl w-full mx-auto flex 2xl:gap-10 gap-16 lg:flex-nowrap justify-center flex-wrap  items-center lg:w-[58vw]   my-10">

        <div data-aos="fade-down" className=" ">
          <img src={img1} alt="" />
        </div>

        <div data-aos="fade-up" className="w-full lg:w-1/2  px-4  font-inter-serif">
          <h1 className="text-[24px] font-semibold ">Mustafa Ramzan </h1>
          <h1 className="text-[16px] font-semibold py-1">Chief Executive Officer</h1>
          <p className="text-[14px]  text-justify ">
            An imaginer and doer with over 20 years of experience, I’m a self-described misfit, rebel, and crazy mind in the world of business. I have a proven track record of transforming businesses and building some from the ground up across FMCG, telecom, and digital financial services. My work spans not only leading businesses but also advising private sector giants and development finance institutions on leveraging data and insights to drive growth. As an AI enthusiast, I’m on a mission to create Sentient organizations—intelligent, adaptive entities that harness AI to continuously evolve and anticipate change. Central to this mission is a commitment to upskilling teams and embedding data-driven decision-making across organizations, enabling them to thrive in the age of AI.
          </p>
        </div>

      </div>

      <div className="max-w-screen-2xl w-full mx-auto flex 2xl:gap-10 gap-16 por justify-center lg:flex-nowrap flex-wrap items-center lg:w-[58vw]  my-10">

        <div data-aos="fade-up" className="w-full lg:w-1/2 px-4 font-inter-serif">
          <h1 className="text-[24px] font-semibold ">Kashif Riaz </h1>
          <h1 className="text-[16px] font-semibold py-1">Chief Operating Officer</h1>
          <p className="text-[14px]  text-justify ">
            A data strategist and leader with over 18 years of experience leading large-scale enterprise data transformation programs around the globe. Enabling customers to build data strategies to better manage, utilize, and monetize data, including identifying business improvement and aligning organizational capabilities.
          </p>
        </div>

        <div data-aos="fade-down" className=" ">
          <img src={img2} alt="" />
        </div>

      </div>



    </main>
  );
}

export default Team;
