import React from "react";
import { NavLink } from "react-router-dom";



function Last() {
  return (
    <main className="bg-[#FAFAFA]  py-10 ">     

<div className="max-w-screen-2xl w-full mx-auto px-4 lg:px-24">


          <div className="flex justify-between items-center flex-wrap gap-y-5 ">

            <div data-aos="fade-left" className=" font-inter-serif ">
              <h1 className=" font-bold text-[24px] ">Unlock the Power of Data & AI for Your Business. Let’s Connect!</h1>
              {/* <p className="font-light text-[14px]">the quick fox jumps over the lazy dog</p> */}
            </div>

            <NavLink to='/contact' data-aos="fade-right" className="flex justify-center lg:justify-start">
  <div className="font-inter-serif">
    <h1 className="bg-[#25537E] flex justify-center inline-block rounded-full py-3 px-8 cursor-pointer text-white text-[14px]">
      Let's Talk
    </h1>
  </div>
</NavLink>

          </div>
          </div>
    
    </main>
  );
}

export default Last;
