import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import LandingPage from "../components/LandingPage";
import AboutUs from "../components/AboutUs";
import Field from "../components/Field";
import Footer from "../components/Footer";
import Last from "../components/Last";

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <>
      <main className=" ">
        <div className=" z-16">
          <Navbar />
        </div>
        <LandingPage />
        <AboutUs />      
        <Field />
        <Last />
        <Footer />
      </main>
    </>
  );
}

export default App;
