import React from "react";
import bottom from '../assets/vector/bottom.png';
import top from '../assets/vector/top.png';
// import bgshadow from '../assets/vector/BG shade.png';

function BlogsPor({ blog, Olblog, blog4, blog2, point, pointBold, point2, blogRepet,blogRepet01,blogRepet0, blogRepet2, related }) {

  return (
    <main className="bg-shad relative max-w-screen-2xl w-full mx-auto ">
      <div className="absolute top-[-65px] left-[-50px] w-[800px] h-[100px] bg-[#47DEDE85] blur-[250px]"></div>
      <div className=" px-4 py-2 lg:px-[18vw] 2xl:px-[15vw] -mt-[50px] lg:mt-0">

        {blog &&
          blog.map((item, index) => (
            <div key={index} className="text-left font-inter-serif ">
              <h1 className="text-[24px] font-semibold my-5 ">{item.title}</h1>
              <p className="text-[16px] leading-[29px] font-light">{item.para}</p>
              <p className="text-[16px] my-5 leading-[29px] font-light">{item.para2}</p>
            </div>
          ))}
        {Olblog &&
          Olblog.map((item, index) => (
            <div key={index} className="text-left font-inter-serif ">
              <h1 className="text-[24px] font-semibold my-5 lg:my-5 ">{item.title}</h1>
              <p className="text-[16px] leading-[29px] font-light">{item.para}</p>
              <ol className="ol ul-s   text-[16px]  leading-[29px] font-light">
                <li className="ms-6"><span className="font-normal">{item.span1}</span>{item.p1}</li>
                <li className="ms-6"><span className="font-normal">{item.span2}</span>{item.p2}</li>
                <li className="ms-6"><span className="font-normal">{item.span3}</span>{item.p3}</li></ol>
            </div>
          ))}

        {blog4 &&
          blog4.map((item, index) => (
            <div key={index} className="text-left font-inter-serif ">
              <h1 className="text-[24px] font-semibold my-5  ">{item.heading}</h1>
              <h1 className="text-[20px] font-semibold mt-5 ul-s">{item.title}</h1>
              <p className="text-[16px] leading-[29px] font-light ul-s">{item.para}</p>
            </div>
          ))}

        {blog2 && blog2.map((item, index) => (
          <div key={index} className="text-left font-inter-serif">
            <h1 className=" text-[24px] font-semibold my-5 lg:my-8 ">{item.heading}</h1>
            <h2 className="ul-s mt-5 text-[20px] font-semibold">{item.maintitle}</h2>
            <h2 className="ul-s mt-5 text-[16px] font-semibold">{item.title}</h2>
            <h2 className=" ul-s  text-[16px] font-light">{item.title1}</h2>
            <ul className="ul ul-s   text-[16px]  leading-[29px] font-light">
              <li className="ms-6">{item.p1}</li>
              <li className="ms-6">{item.p2}</li>
              <li className="ms-6">{item.p3}</li>
            </ul>
            <h2 className="ul-s text-[16px] font-light ">{item.title2}</h2>

          </div>
        ))}

        {point && point.map((item, index) => (
          <div key={index} className="text-left font-inter-serif">
            <h1 className=" text-[24px] font-semibold my-5 lg:my-8 ">{item.heading}</h1>
            <h2 className="ul-s mt-5 text-[20px] font-semibold">{item.maintitle}</h2>
            <h2 className="ul-s mt-5 text-[20px] font-semibold">{item.title}</h2>
            <h2 className="ul-s text-[16px] font-light">{item.title1}</h2>
            <ul className="ul ul-s text-[16px]  leading-[29px] font-light">
              <li className="ms-6"><span className="font-normal">{item.span1}</span>{item.p1}</li>
              <li className="ms-6"><span className="font-normal">{item.span2}</span>{item.p2}</li>
              <li className="ms-6"><span className="font-normal">{item.span3}</span>{item.p3}</li>
            </ul>
            <h2 className="ul-s text-[16px] font-light ">{item.title2}</h2>

          </div>
        ))}
       

        {pointBold && pointBold.map((item, index) => (
          <div key={index} className="text-left font-inter-serif">
            <h1 className="text-[24px] font-semibold my-5 lg:my-8 ">{item.heading}</h1>
            <h2 className="mt-5 ul-s text-[20px] font-semibold">{item.title}</h2>
            <ul className="ul-s">
              <li className=" ul mt-5 lg:text-[20px] text-[16px]  font-semibold">{item.subtitle}</li>
              <li className="ul ul-s ms-4 text-[16px]  leading-[29px] font-light">{item.p1}</li>
              <li className="ul ul-s ms-4 text-[16px]  leading-[29px] font-light">{item.p2}</li>
            </ul>
            <ul className="ul-s">
              <li className=" ul  mt-5 lg:text-[20px] text-[16px]  font-semibold">{item.subtitle2}</li>
              <li className="ul ul-s  text-[16px]  leading-[29px] font-light">{item.p3}</li>
              <li className="ul ul-s  text-[16px]  leading-[29px] font-light">{item.p4}</li>
            </ul>
            <ul className="ul-s">
              <li className=" ul mt-5  lg:text-[20px] text-[16px]  font-semibold">{item.subtitle3}</li>
              <li className="ul ul-s ms-4 text-[16px]  leading-[29px] font-light">{item.p4}</li>
              <li className="ul ul-s ms-4 text-[16px]  leading-[29px] font-light">{item.p5}</li>
            </ul>

            <h2 className="text-[20px] font-light ">{item.title2}</h2>

          </div>
        ))}

        {point2 && point2.map((item, index) => (
          <div key={index} className="text-left font-inter-serif">
            <h1 className="text-[24px] font-semibold my-5 lg:my-8 ">{item.heading}</h1>
            <h2 className="ul-s mt-5 text-[20px] font-semibold">{item.title}</h2>
            <h2 className="ul-s text-[16px] font-light">{item.title1}</h2>
            <ul className="ul text[15px] font-light">
              <li className="ul-s"><span className="font-normal">{item.span1}</span>{item.p1}</li>
              <li className="ul-s"><span className="font-normal">{item.span2}</span>{item.p2}</li>
            </ul>
            <h2 className="ul-s text-[16px] font-light ">{item.title2}</h2>

          </div>
        ))}


        {blogRepet0 &&
          blogRepet0.map((item, index) => (
            <div key={index} className="text-left font-inter-serif ">
              <h1 className="  my-10 text-[24px] font-semibold">{item.mainheading}</h1>
              <p className=" text-[16px]  leading-[29px] font-light -mt-5 mb-5" >{item.p1}</p>
              <h1 className=" ul-s   text-[20px] font-semibold">{item.heading}</h1>
              <p className=" text-[16px]  leading-[29px] font-light  ul-s" >{item.paragraph}</p>
              <p className=" text-[16px]  leading-[29px] font-light ul-s" >{item.paragraph1}</p>
              <p className=" text-[16px]  leading-[29px] font-light ul-s" >{item.paragraph2}</p>
            


            </div>
          ))}

        {blogRepet &&
          blogRepet.map((item, index) => (
            <div key={index} className="text-left font-inter-serif ">
              <h1 className="mt-10 text-[24px] font-semibold">{item.heading}</h1>
              <p className=" mt-5 text-[16px]  leading-[29px] font-light " >{item.p1}</p>
              
              <h1 className="my-5 text-[20px] font-semibold ul-s">{item.title}</h1>
              <p className=" text-[16px]  leading-[29px] font-light  ul-s" >{item.paragraph}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light ul-s" >{item.paragraph1}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light ul-s" >{item.paragraph2}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light ul-s" >{item.paragraph3}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light ul-s" >{item.paragraph4}</p>


            </div>
          ))}

        {blogRepet01 &&
          blogRepet01.map((item, index) => (
            <div key={index} className="text-left font-inter-serif ">
              <h1 className="mt-10 text-[24px] font-semibold">{item.heading}</h1>
              <p className=" mt-5 text-[16px]  leading-[29px] font-light " >{item.p1}</p>
              
              <h1 className="my-5 text-[20px] font-semibold ul-s">{item.title}</h1>
              <p className=" text-[16px]  leading-[29px] font-light" >{item.paragraph}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light" >{item.paragraph1}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light " >{item.paragraph2}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light " >{item.paragraph3}</p>
              <p className=" text-[16px] -mt-5 leading-[29px] font-light " >{item.paragraph4}</p>


            </div>
          ))}

        {blogRepet2 &&
          blogRepet2.map((item, index) => (
            <div key={index} className="text-left font-inter-serif my-14  ">
              <h1 className="text-[24px] pb-5  font-semibold ">{item.title}</h1>
              <p className=" text-[16px] leading-[29px] font-light " >{item.paragraph}</p>
              <p className=" text-[16px]  leading-[29px] font-light " >{item.paragraph2}</p>
              <h1 className="text-[24px] pb-5 -mt-8 font-semibold ">{item.title2}</h1>
              <p className=" text-[16px]  leading-[29px] font-light " >{item.paragraph3}</p>
            </div>
          ))}


       

        {/* <div className="absolute top-0 left-0  "><img src={bgshadow} alt="bg-shadow" /></div> */}
        <div className="absolute top-0 left-0  "><img src={bottom} alt="TOP-IMG" /></div>
        <div className="absolute bottom-0 right-0 "><img src={top} alt="BOTTOM-IMG" /></div>
      </div>
    </main>
  );
}

export default BlogsPor;
