import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/Technology Modernization.png";
import img2 from "../assets/vector/Process Automation.png";
import img3 from "../assets/vector/Cybersecurity & Compliance.png";
import img4 from "../assets/vector/Enterprise Architecture Alignment.png";
import service from '../assets/media/Digital-Transformation.png' ;
import ServiceAbout from "../components/ServiceAbout";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";





function Digital() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const navigate = useNavigate();

  const repeat = [
    {
      img: img1,
      title: "Technology ",
      title2: " Modernization",
      description:
        "Our technology modernization services upgrade legacy systems to modern, scalable architectures, enhancing agility and supporting future growth.",
    },
    {
      img: img2,
      title: "Process ",
      title2: " Automation",
      description:
        "We automate repetitive processes with AI-powered solutions, improving efficiency, reducing costs, and freeing up resources for higher-value tasks.",
    },
    {
      img: img3,
      title: "Cybersecurity ",
      title2: " Compliance",
      description:
        "Our cybersecurity and compliance services protect your data and systems, ensuring robust security measures and adherence to regulatory standards.",
    },
    {
      img: img4,
      title: "Enterprise Architecture ",
      title2: " Alignment",
      description:
        "We align enterprise architecture with business goals, creating an adaptable framework that supports efficient operations and strategic initiatives.",
    },
  ];

  return (
    <main>
      <Navbar />

      <div  className="max-w-screen-2xl w-full mx-auto pt-24 mb-3 px-4 lg:px-10">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <h1 className="text-[16px] lg:text-[24px] font-inter-serif">Back</h1>
        </div>
      </div>

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-10 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full mb-6  font-inter-serif lg:px-16  text-white">
          <p className="pt-20 lg:pt-20 2xl:pt-32 font-semibold text-[36px] lg:text-[48px] lg:text-start text-center">
          Digital Transformation</p>
          <p className="mt-3 lg:mt-0 text-[16px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-8/12">Empowering businesses to thrive in the digital era with innovative transformation solutions.</p>
        </div>
      </div>

      <ServiceAbout
        image={service}
        contentText1="Our boutique firm enhances  technology by providing comprehensive assessments, strategic designs, and agile implementations tailored for future industry demands. We assist in aligning technological advancements with business goals to improve efficiency, enhance experiences, and ensure compliance. "

      />

      <Data repeat={repeat} />
      <Footer />
    </main>
  );
}

export default Digital;
