import React, { useEffect } from "react";


function AboutExp() {


  return (
    <main className="bg-[#F2F4F5] mt-20   ">

      <div className="max-w-screen-2xl w-full mx-auto px-4 2xl:px-80 lg:px-56 py-24 ">

        <div className="text-center  font-inter-serif">
          <h1 data-aos="fade-up" className="text-[36px] font-bold">
            SentientAI Enhancing Your Experience
          </h1>
          <p className="leading-[30px]  mt-5 ">


            Our comprehensive approach includes strategic advisor, talent development, and cutting-edge AI solutions to drive sustainable growth, enhance customer experience, improve operational efficiency, and manage risk effectively.
            SentientAI is an innovative AI products and services company dedicated to transforming organizations into SentientAI Enterprises. We empower businesses to become AI-first by leveraging advanced AI and data strategies.


          </p>
        </div>

      </div>



    </main>

  );
}

export default AboutExp;
