import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import contactImage from "../assets/media/contact.png";
import mailIcon from "../assets/vector/mail.png";
import phone from "../assets/vector/phne.png";
import map from "../assets/vector/MapPinArea.png";
import 'leaflet/dist/leaflet.css';
import emailjs from '@emailjs/browser';
import Last from "../components/Last";
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

function Contact() {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const data = [
    {
      img: mailIcon,
      title: "Our email",
      para: (
        <a href="mailto:Info@thesentientai.tech" className="text-[#25537E]">
          Info@thesentientai.tech
        </a>
      ),
    },
    {
      img: phone,
      title: "Call us",
      para: "+971-585855917",
      para2: "+971-508402089",
    },
    {
      img: map,
      title: "Find us",
      para: "SentientAI L.L.C - FZ.Meydan Grandstand, ",
      para2: "6th floor, Meydan Road, Nad Al Sheba, Dubai, U.A.E. ",
    },
  ];

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        'service_clihv02',
        'template_50wrc1s',
        form.current,
        {
          publicKey: 'IrW7qkSwWVU8dPkuI',
        }
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log('Message Sent');
          toast.success("Message sent successfully!");
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error("Failed to send message. Please try again later.");
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-up"
        className="h-[35vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center lg:px-24"
        style={{
          backgroundImage: `linear-gradient(to right, #25537EE5, #3DB7B7E5), url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full text-center lg:text-start font-semibold mb-6 text-[36px] lg:text-[48px] text-white">
          <p className="pt-24 font-inria-sans font-semibold">Contact Us</p>
        </div>
      </div>

      <div className="bg-[#F4F4F47D]">
        <div className="lg:p-16 p-4 max-w-screen-2xl mx-auto w-full">
          <form
            ref={form}
            onSubmit={sendEmail}
            data-aos="fade-up"
            className="my-8 flex lg:flex-nowrap flex-wrap flex-col-reverse lg:flex-row gap-5 items-center"
          >
            <div className="w-full lg:w-1/2">
              <div data-aos="fade-down">
                <img src={contactImage} alt="Contact Illustration" />
              </div>
            </div>

            <div className="w-full lg:w-1/2 font-inter-serif">
              <h1 className="text-[40px] lg:text-[48px] font-inter-serif leading-[63px] font-bold bg-gradient-to-r from-[#25537E] to-[#3DB7B7] bg-clip-text text-transparent">
                Let’s <span className="text-[#3DB7B7]">talk</span>
              </h1>
              <div className="text-[15px] font-semibold leading-[24px] mt-3">
                <p>Let’s Connect and Create Together!</p>
                <p>Reach out for inquiries, collaborations, or support – we’re here to help!</p>
              </div>
              <div className="mt-5 grid grid-cols-1 gap-y-5 sm:grid-cols-2 lg:grid-cols-2">
                {/* First Name */}
                <div className="sm:col-span-full">
                  <input
                    name="user_name"
                    required
                    type="text"
                    id="first-name"
                    autoComplete="given-name"
                    placeholder="Name"
                    className="block w-full p-4 text-gray-900 border border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>
                {/* Email */}
                <div className="sm:col-span-full">
                  <input
                    name="user_email"
                    required
                    type="email"
                    id="email"
                    autoComplete="email"
                    placeholder="Email"
                    className="block w-full p-4 text-gray-900 border border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>

                {/* Phone */}
                <div className="sm:col-span-full">
                  <input
                    name="number"
                    required
                    type="tel"
                    id="phone"
                    autoComplete="tel"
                    placeholder="Phone number"
                    className="block w-full p-4 text-gray-900 border border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>

                {/* Message */}
                <div className="col-span-full">
                  <textarea
                    name="message"
                    required
                    id="message"
                    autoComplete="off"
                    placeholder="Message"
                    rows="4"
                    className="block w-full p-4 text-gray-900 border border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`text-center sm:col-span-full p-4 rounded-full bg-[#25537E] text-white cursor-pointer ${isSubmitting ? 'opacity-50' : ''}`}
                >
                  <h1 className="text-[18px]">{isSubmitting ? 'Sending...' : 'Send'}</h1>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="max-w-screen-2xl mx-auto w-full px-4 lg:px-20">
        <div className="border border-[#D1CDCD] rounded-lg my-16 py-6">
          <div className="px-4 lg:px-10">
            <h1 className="text-[20px] lg:text-[24px] text-center mt-4">
              Your questions are important to us – let’s connect!
            </h1>
            <hr className="my-10" />

            <div className="flex gap-y-10 items-center justify-center flex-wrap lg:flex-nowrap">
              {data &&
                data.map((item, index) => (
                  <div key={index} className="w-full flex gap-5 items-center">
                    <div>
                      <img src={item.img} alt={item.title} />
                    </div>
                    <div className="text-[14px] font-inter-serif">
                      <h1 className="font-semibold">{item.title}</h1>
                      <p className="cursor-pointer">{item.para}</p>
                      <p>{item.para2}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Last />
      <Footer />

      <ToastContainer />
    </main>
  );
}

export default Contact;
