import React, { useEffect } from "react";
import uni from '../assets/banner/Untitled-1.png'

function Mission() {


  return (
    <main className=" bg-white relative overflow-hidden">

    <div className="max-w-screen-2xl w-full mx-auto  p-10 py-16 font-inter-serif ">
    
      <div className="text-center">
        <h1 data-aos="fade-up" className="text-[36px] lg:text-[48px] font-inter-serif font-bold">
          Where AI Meets <span className="font-bold bg-gradient-to-r from-[#3DB7B7] to-[#25537E] bg-clip-text text-transparent">Business Consciousness</span>
        </h1>
      </div>
  
      <div className=" w-full flex flex-wrap justify-center gap-10 px-2 2xl:px-16 items-center mt-5 text-center">
        <div data-aos="fade-left" className="w-full lg:w-1/3 ">
          <h1 className="font-bold text-[32px] py-3 leading-[47px]">Our Mission</h1>
          <p className="leading-[24px]">Our mission is to transform organizations into AI-first enterprises by providing strategic guidance, developing innovative AI solutions, and equipping teams with the necessary skills.</p>
        </div>
  
        <div data-aos="fade-right" className="w-full lg:w-1/3">
          <h1 className="font-bold text-[32px] py-3  leading-[47px]">Our Vision</h1>
          <p className="leading-[24px]">To be the world’s leading partner in creating intelligent, AI-driven enterprises that are agile, innovative, and capable of sustained growth in a rapidly changing digital landscape.</p>
        </div>
  
      </div>
  
    </div>
  
  
    <div className="absolute top-[-60px] left-[-50px] w-[200px] h-[200px] bg-[#3DB7B7] blur-3xl"></div>
    <div className="absolute bottom-[-50px] right-0"><img src={uni} alt="" /></div>
  
  </main>
  
  );
}

export default Mission;
