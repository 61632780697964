import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/Data Strategy.png";
import img2 from "../assets/vector/Vector.png";
import img3 from "../assets/vector/Data Architecture Roadmap.png";
import img4 from "../assets/vector/Talent & Data Culture Advisory.png";
import ServiceAbout from "../components/ServiceAbout";
import service from '../assets/media/Data--AI-Strategy.png'
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";




function AIstrategy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const navigate = useNavigate();

  const repeat = [
    {
      img: img1,
      title: "Data ",
      title2: " Strategy",
      description:
        "Our Data Strategy services help organizations unlock the full potential of their data assets. We design tailored strategies that align with your business objectives, ensuring data becomes a central driver of growth and innovation.",
    },
    {
      img: img2,
      title: "Data Governance",
      title2: " Framework",
      description:
        "We create comprehensive Data Governance frameworks to ensure data quality, compliance, and security. Our approach establishes clear policies and controls, empowering your organization to make data-driven decisions with confidence.",
    },
    {
      img: img3,
      title: "Data Architecture ",
      title2: " Roadmap",
      description:
        "Our Data Architecture Roadmap provides a scalable blueprint for managing and integrating data across your organization. We design robust, future-proof architectures that support data accessibility, reliability, and efficiency.",
    },
    {
      img: img4,
      title: "Talent & Data Culture ",
      title2: "Advisory",
      description:
        "Our Talent & Data Culture Advisory services foster an AI-ready workforce and data-driven mindset. Through targeted training and advisory, we empower your team to fully leverage data for strategic advantage.",
    },
  ];

 

  return (
    <main>
      <Navbar />

      <div  className="max-w-screen-2xl w-full mx-auto pt-24 mb-3 px-4 lg:px-10">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <h1 className="text-[16px] lg:text-[24px] font-inter-serif">Back</h1>
        </div>
      </div>

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-10 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full  mb-6  font-inter-serif lg:px-16 text-white">
          <h1 className="pt-20 lg:pt-24 2xl:pt-32 font-semibold  text-[36px] lg:text-start text-center lg:text-[48px]">Data & AI Strategy</h1>
          <p className="mt-3 lg:mt-0 text-[16px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-8/12">Driving innovation with tailored Data & AI strategies to unlock your business's full potential.</p>
          
        </div>
      </div>

      <ServiceAbout 
       image={service}
       contentText1="Our Strategic Advisory services are designed to guide organizations in becoming AI-first enterprises by crafting tailored AI and data strategies that align with their specific business goals. "
       contentText2="In today’s digital landscape, having a well-defined AI strategy is crucial for maintaining a competitive edge, driving innovation, and achieving operational efficiencies."
       />
      <Data
      repeat={repeat} />
      <Footer />
    </main>
  );
}

export default AIstrategy;
