import React from "react";
import logo from "../assets/logo/logo.png";
import insta from "../assets/vector/insta.png";
import fb from "../assets/vector/fb.png";
import linked from "../assets/vector/linkind.png";
import { Link, NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="max-w-screen-2xl w-full mx-auto ">
      <div className="px-14">
        <hr className="bg-black h-1 mb-5" />
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between items-center py-5 text-center md:text-left">
        <div>
    <img src={logo} alt="" />
  </div>
  <div>
    <h1 className="text-[#131126] text-[14px]">
      All rights reserved ® thesentientai.tech | Terms and conditions apply!
    </h1>
  </div>
  <div className="flex items-center gap-10 text-[#979797] text-[14px] cursor-pointer justify-center md:justify-start">
    {/* <img src={fb} alt="Facebook" /> */}
    <NavLink to ='https://www.linkedin.com/company/thesentientai/' target="_blank" rel="noopener noreferrer">
    <img src={insta} alt="insta" />
    </NavLink>
    {/* <img src={linked} alt="Payment" /> */}
  </div>
</div>

      </div>
    </footer>
  );
}

export default Footer;
