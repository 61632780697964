import React, { useEffect, useState } from "react";
import logo from "../assets/logo/logo.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    if (navItems[index].submenu) {
      setDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
    setHoveredIndex(null);
  };

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Services", path: "/services", submenu: [] },
    { name: "Our Vision", path: "/our-vision" },
    { name: "Blog", path: "/blog" },
    { name: "Contact", path: "/contact" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header
      className={`fixed z-[999] py-5 px-14 w-full border-b-2 transition-all duration-300 ${isScrolled || isOpen
          ? "bg-white bg-opacity-50 backdrop-blur-md border border-gray-200"
          : "bg-white"
        }`}
    >
      <nav className="max-w-screen-2xl w-full mx-auto flex flex-col lg:flex-row justify-between items-center">
        <div className="flex justify-between items-center w-full lg:w-auto">
          <NavLink to="/" className="logo cursor-pointer">
            <img src={logo} alt="Logo" />
          </NavLink>
          <button onClick={toggleMenu} className="lg:hidden">
            <FiMenu className="text-2xl" />
          </button>
        </div>
        <div
          className={`grid lg:block w-full lg:w-auto transition-all ease-out duration-700 ${isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
            }`}
        >
          <ul
            className={`overflow-hidden text-[#000] lg:overflow-visible links flex flex-col lg:flex-row gap-6 lg:gap-10 items-center cursor-pointer transition-all ${isOpen && "py-4"
              } lg:py-0`}
          >
            {navItems.map((item, index) => (
              <li
                key={index}
                className={`capitalize lg:py-2 py-0 ${index === 4
                    ? "border border-[#47DFDF]  hover:border-[#25537E] hover:bg-[#25537E] rounded-full px-8 py-1  text-[#47DFDF] hover:text-white"
                    : "relative"
                  }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink
                  to={item.path}
                  className={`flex items-center ${isActive(item.path) ? "font-semibold text-black" : ""
                    }`}
                >
                  {item.name}
                  {index === 4 && <FaArrowRightLong className="ms-2" />}
                </NavLink>

                {item.submenu && dropdownOpen && hoveredIndex === index && (
                  <div
                    className="absolute md:block hidden top-10   flex-wrap lg:flex-nowrap lg:h-[20rem] w-64 z-20 h-fit "
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="bg-[#EEF0F2] lg:h-[18rem] flex items-center px-6 shadow-lg rounded-md  lg:p-4 grid grid-cols-1 md:grid-cols-1">
                    

                     
                      <div className="px-2  lg:px-4 w-full">
                        <ul className="w-full">
                          <li className="p-2 text-black hover:bg-gray-200 hover:rounded-lg hover:shadow-md hover:text-black">
                            <NavLink
                              to="/data-ai-strategy"
                              className="block  w-full text-left text-[14px]"
                            >
                              <p>Data & AI Strategy</p>
                            </NavLink>
                          </li>
                          <li className="p-2 text-black hover:bg-gray-200 hover:rounded-lg hover:shadow-md hover:text-black">
                            <NavLink
                              to="/data-ai-implementation"
                              className="block  w-full text-start text-[14px]"
                            >
                              <p>Data & AI Implementation </p>
                            </NavLink>
                          </li>
                          <li className="p-2 text-black text-blackhover:bg-gray-200 hover:rounded-lg hover:shadow-md hover:text-black">
                            <NavLink
                              to="/ai-product"
                              className="block  w-full text-left text-[14px]"
                            >
                              <p>AI Products</p>
                            </NavLink>
                          </li> 
                          <li className="p-2 text-black hover:bg-gray-200 hover:rounded-lg hover:shadow-md hover:text-black">
                            <NavLink
                              to="/managed-service"
                              className="block  w-full text-left text-[14px]"
                            >
                              <p>Managed Services</p>
                            </NavLink>
                          </li>
                          <li className="p-2 text-black hover:bg-gray-200 hover:rounded-lg hover:shadow-md hover:text-black">
                            <NavLink
                              to="/digital-transformation"
                              className="block  w-full text-start text-[14px]"
                            >
                              <p>Digital Transformation</p>
                            </NavLink>
                          </li>
                          <li className="p-2 text-black hover:bg-gray-200 hover:rounded-lg hover:shadow-md hover:text-black">
                            <NavLink
                              to="/talent-empowerment"
                              className="block  w-full text-start text-[14px]"
                            >
                              <p>Talent Empowerment</p>
                            </NavLink>
                          </li>
                         
                        </ul>
                      </div>

                    </div>
                  </div>
                )}

              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
