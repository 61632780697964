import { useState, useEffect } from "react";
import img1 from '../assets/banner/MAINBANNER.png';
import img2 from '../assets/banner/img2.png';
import img3 from '../assets/banner/img3.png';
import img4 from '../assets/banner/IMG4.png';

function LandingPage() {
  const slides = [
    {
      image: img1,
      text: "Transforming Businesses into AI-First Enterprises",
      para: "We empower organizations to adopt AI-first strategies, enabling smarter decisions, enhanced agility, and sustained competitive advantage.",
    },
    {
      image: img2,
      text: "Unlocking the Power of Data for Strategic Growth",
      para: "Our data solutions help you harness actionable insights, turning raw data into valuable intelligence that drives business success.",
    },
    {
      image: img3,
      text: "Future-Proofing Through Advanced Data Governance",
      para: "We build robust governance frameworks that ensure data quality, compliance, and security, enabling trustworthy data-driven growth.",
    },
    {
      image: img4,
      text: "Building AI Talent for Tomorrow’s Challenges",
      para: "Our tailored training and advisory services equip your team with the skills needed to embrace and excel in an AI-driven world.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [bgImage, setBgImage] = useState(slides[0].image);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });
  }, [slides]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isTransitioning) {
      setBgImage(slides[currentSlide].image);
      setTimeout(() => setIsTransitioning(false), 1000); 
    }
  }, [currentSlide]);

  const handleDotClick = (index) => {
    setIsTransitioning(true);
    setCurrentSlide(index);
    setBgImage(slides[index].image);
    setTimeout(() => setIsTransitioning(false), 1000);
  };

  return (
    <main
      className="relative py-20 lg:py-56 2xl:py-64 bg-cover bg-center transition-all ease-in-out"
      style={{
        backgroundImage: `url(${bgImage})`,
        transition: "background-image 1s ease-in-out",
      }}
    >
      <div className="relative z-10 max-w-screen-2xl px-0 lg:px-20 w-full mx-auto mt-10">
        <div className="text-Structure lg:mt-32 md:px-10 px-2 transition-opacity duration-500 ease-in-out">
          <div>
            <h1
              className={`text-[30px] lg:text-[50px] text-center lg:text-start leading-[50px] lg:leading-[50px] text-white font-bold font-inria-serif transition-all duration-3000 ease-in-out transform 
              }`}
            >
              {slides[currentSlide].text}
            </h1>
            <p
              className={`text-[16px] lg:text-[24px] text-center lg:text-start leading-[30px] text-white py-3 font-inter-sans px-2 transition-all duration-3000 ease-in-out transform 
              }`} 
            >
              {slides[currentSlide].para}
            </p>
          </div>
        </div>

        <div className="absolute bottom-[-45px] lg:bottom-[-170px] 2xl:bottom-[-215px] left-1/2 transform -translate-x-1/2 flex space-x-4">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full bg-white cursor-pointer transition-all duration-300 ${
                currentSlide === index ? "bg-opacity-100 " : "bg-opacity-50"
              }`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </main>
  );
}

export default LandingPage;
