import React, { useEffect } from "react";

function AboutExp() {


  const items = [
    {
      number: "01",
      title: "Customer Experience",
      description: "Empowering businesses to deliver personalized, seamless customer experiences by harnessing advanced AI insights and automation."
    },
    {
      number: "02",
      title: "Operational Efficiency",
      description: "Streamlining operations through AI-driven efficiencies that optimize workflows, reduce costs, and enhance productivity."
    },
    {
      number: "03",
      title: "Risk & Fraud",
      description: "Proactively identifying and mitigating risks with intelligent AI solutions that detect and prevent fraud in real time."
    },
    {
      number: "04",
      title: "Revenue Growth",
      description: "Accelerating growth through data-informed AI strategies that unlock new revenue streams and maximize business impact."
    },
  ];


  return (
    <main className=" bg-[#F2F4F5] overflow-hidden relative ">
      <div className="max-w-screen-2xl w-full mx-auto  py-20 ">

    

      <div data-aos="fade-up" className="px-4 md:px-44">
        <h1 className="font-inter-serif font-semibold  text-[36px] md:text-[48px] text-center text-black " >Our Experience </h1>
      </div>

      <div className="w-full flex flex-wrap md:flex-nowrap gap-16 justify-around items-center px-14 lg:px-32 2xl:px-40 my-10">
        {items.map((item, index) => (
          <div data-aos="fade-down" className="text-center" key={index}>
            <div className="border-[#47DFDF] border rounded-full inline-block px-2">
              <h1 className="text-[28px] lg:text-[40px] text-center p-1">{item.number}</h1>
            </div>
            <div>
              <h1 className="text-[20px] font-semibold">{item.title}</h1>
              <p className="text-[14px] mt-2 text-[#7D7D7D]">{item.description}</p>
            </div>
          </div>
        ))}
      </div>

      </div>
      <div className="absolute top-[-60px] right-[-50px] w-[200px] h-[200px] bg-[#3DB7B7] blur-3xl"></div>
    </main>
  );
}

export default AboutExp;
