import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import blogImage from "../assets/banner/blog.png";
import BlogCard from "../components/BlogCard";

function Blogs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const paragraphs = [
    " Insights and innovations:Exploring the future of AI",
  ];

  return (
    <main className="">
      <Navbar />  
      <Banner
        bgImage={blogImage}
        paragraphs={paragraphs} />
      <BlogCard/>
      <Footer />
    </main>
  );
}

export default Blogs;
