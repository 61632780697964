import React from "react";


function Banner({ bgImage, heading, paragraphs }) {
  return (
    <main
    data-aos="fade-up"
      className="  h-fit bg-cover bg-center  lg:py-40 py-20  px-4 lg:px-10 relative top-[4rem]  left-0 "
      style={{
        backgroundImage: `url(${bgImage})`,
        objectFit:"cover",
      }}
    >
      <div
        data-scroll
        data-scroll-speed="-.8"
        className="max-w-screen-2xl w-full mx-auto  2xl:px-20"
      >
        <div data-aos="fade-right" className="text-Structure   px-4 lg:px-16 text-white font-inter-serif ">
          <div className="text-[36px] lg:text-[48px] text-center font-bold flex lg:justify-start justify-center items-center">
            <h1>{heading}</h1>
          </div>
          {paragraphs && 
          paragraphs.map((item, index) => (
            <div
              key={index}
              className="font-semibold text-[18px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-7/12 "
            >
              <h1>{item}</h1>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}

export default Banner;
