import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import serviceImage from "../assets/banner/service.png";
import service from '../assets/media/23 1.png'
import ServiceAbout from "../components/ServiceAbout";
import Field from "../components/Field";

function Service() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const paragraphs = [
    " At SentientAI, we harness the power of artificial intelligence to create innovative solutions that drive business transformation.",
  ];

  return (
    <main className="">
      <Navbar />
      <Banner
        bgImage={serviceImage}
        heading="Our Services"
        paragraphs={paragraphs} />

      <div className="my-28 ">
        <ServiceAbout
          image={service}
          contentText1="At SentientAI, we’re your strategic partner in innovation. Our skilled development team is dedicated to delivering tailored solutions that meet your unique needs and drive your business success."
          contentText2="We specialize in implementing new capabilities, modernizing legacy systems, and optimizing the effectiveness and efficiency of your existing operations."
        />
      </div>
      
      <Field />
      <Footer />
    </main>
  );
}

export default Service;
