import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/Proactive Monitoring.png";
import img2 from "../assets/vector/System & Application Maintenance.png";
import img3 from "../assets/vector/Staff Augmentation for Data & AI.png";
import img4 from "../assets/vector/Strategic Guidance.png";
import ServiceAbout from "../components/ServiceAbout";
import service from '../assets/media/Managed-Services.png';
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";





function Managed() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const navigate = useNavigate();

  const repeat = [
    {
      img: img1,
      title: "Proactive ",
      title2: " Monitoring",
      description:
        "Our proactive monitoring services of data ecosystem to detect and resolve issues before they impact performance, ensuring system reliability and uptime.",
    },
    {
      img: img2,
      title: "System & Application ",
      title2: " Maintenance",
      description:
        "We provide ongoing system and application maintenance to optimize performance, security, and efficiency, supporting smooth operations.",
    },
    {
      img: img3,
      title: "Staff Augmentation ",
      title2: "for Data & AI",
      description:
        "Our staff augmentation services offer experienced Data and AI professionals to strengthen your team’s capabilities and accelerate project delivery.",
    },
    {
      img: img4,
      title: "Strategic ",
      title2: " Guidance",
      description:
        "We deliver expert strategic guidance to help you navigate AI and data transformation, aligning solutions with your long-term business goals.",
    },
  ];


  return (
    <main>
      <Navbar />

      <div  className="max-w-screen-2xl w-full mx-auto pt-24 mb-3 px-4 lg:px-10">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <h1 className="text-[16px] lg:text-[24px] font-inter-serif">Back</h1>
        </div>
      </div>

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-10 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full mb-6  font-inter-serif lg:px-16  text-white">
          <p className="pt-20 lg:pt-20 2xl:pt-32 font-semibold text-[36px] lg:text-[48px] lg:text-start text-center">
          Managed Services</p>
          <p className="mt-3 lg:mt-0 text-[16px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-8/12">Ensuring seamless operations with reliable and scalable managed services tailored to your needs.</p>
        </div>
      </div>

      <ServiceAbout
        image={service}
       contentText1="Our Managed Services empower your organization to harness the full potential of your digital applications, data assets and artificial intelligence initiatives."
        contentText2="We take the complexity out of managing application, data and AI infrastructure, allowing you to focus on innovation and business growth."

      />

      <Data repeat={repeat} />
      <Footer />
    </main>
  );
}

export default Managed;
