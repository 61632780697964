import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/ac.png";
import img2 from "../assets/vector/Data Governance.png";
import img3 from "../assets/vector/Data Migration.png";
import img4 from "../assets/vector/a1.png";
import img5 from "../assets/vector/Data Engineering.png";
import img6 from "../assets/vector/Reporting & Data Visualization.png";
import img7 from "../assets/vector/Business Intelligence.png";
import img8 from "../assets/vector/FActory.png";
import service from '../assets/media/Data--AI-Implementation.png';
import ServiceAbout from "../components/ServiceAbout";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";



function AIimplement() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const navigate = useNavigate();

  const repeat = [
    {
      img: img1,
      title: "Business ",
      title2: " Use Cases",
      description:
        "We identify and develop AI-driven business use cases that create tangible value, aligning data solutions with your unique operational goals.",
    },
    {
      img: img2,
      title: "Data ",
      title2: " Governance",
      description:
        "Our data governance services establish rigorous policies and controls, ensuring data quality, compliance, and accountability across your organization.",
    },
    {
      img: img3,
      title: "Data ",
      title2: " Migration",
      description:
        "We streamline data migration with secure, efficient processes that minimize disruption, enabling seamless transitions to modern data platforms."
    },
    {
      img: img4,
      title: "Talent & Data",
      title2: " Culture Advisory",
      description:
        "Our Data Science solutions unlock deep insights and predictive capabilities, empowering data-driven decisions and operational improvements.",
    },
    {
      img: img5,
      title: "Data ",
      title2: " Engineering",
      description:
        "We build and optimize data pipelines that enhance data flow, reliability, and scalability, supporting advanced analytics and AI initiatives.",
    },
    {
      img: img6,
      title: "Reporting & Data",
      title2: " Visualization",
      description:"We design intuitive reporting and data visualization that transform complex data into clear, actionable insights for better decision-making."
 },
    {
      img: img7,
      title: "Business",
      title2: " Intelligence",
      description:
        "Our Data Science solutions unlock deep insights and predictive capabilities, empowering data-driven decisions and operational improvements."
    },
    {
      img: img8,
      title: "Data Factory ",
      title2: " Setup",
      description:

        "We set up a robust data ecosystem to automate data processing, ensuring efficient, scalable data stores and tools that support your analytics and AI needs."

    },
  ];

  
  return (
    <main>
      <Navbar />


      <div  className="max-w-screen-2xl w-full mx-auto pt-24 mb-3 px-4 lg:px-10">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <h1 className="text-[16px] lg:text-[24px] font-inter-serif">Back</h1>
        </div>
      </div>
      
      <div
        
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-10 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full mb-6  font-inter-serif lg:px-16  text-white">
          <p className="pt-20 lg:pt-20 2xl:pt-32 font-semibold text-[36px] lg:text-[48px] lg:text-start text-center">Data & AI Implementation</p>
          <p className="mt-3 lg:mt-0 text-[16px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-8/12">Seamlessly transforming your vision into reality with expert Data & AI implementations.</p>
        </div>
      </div>

      <ServiceAbout 
       image={service}
       contentText1="We focus on delivering customized projects that transform raw data into actionable insights and predictive models. Our expert team then designs and implements bespoke solutions, utilizing cutting-edge tools and models to optimize operations and uncover new growth opportunities."
      
       />

      <Data repeat={repeat} /> 
      <Footer />
    </main>
  );
}

export default AIimplement;
