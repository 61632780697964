import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/AI Elevate Online Training Courses.png";
import img2 from "../assets/vector/AI Masterclass On-Premise Focused Trainings.png";
import service from '../assets/media/Talent-Empowerment.png'  ;
import ServiceAbout from "../components/ServiceAbout";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";





function Talent() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const navigate = useNavigate();
  const repeat = [
    {
      img: img1,
      title: "AI Elevate: Online Training ",
      title2: " Courses",
      description:
        "AI Elevate offers flexible, self-paced online courses designed to build foundational and advanced AI skills across all employee levels, empowering teams to leverage AI effectively.",
    },
    {
      img: img2,
      title: "AI Masterclass: On-Premise ",
      title2: "  Focused Trainings",
      description:
        "AI Masterclass delivers intensive, hands-on training sessions at your location, tailored to meet specific team needs and enhance AI proficiency with real-world applications.",
    },
  ];



  return (
    <main>
      <Navbar />

      <div  className="max-w-screen-2xl w-full mx-auto pt-24 mb-3 px-4 lg:px-10">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <h1 className="text-[16px] lg:text-[24px] font-inter-serif">Back</h1>
        </div>
      </div>

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-10 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full mb-6  font-inter-serif lg:px-16  text-white">
          <p className="pt-20 lg:pt-20 2xl:pt-32 font-semibold text-[36px] lg:text-[48px] lg:text-start text-center">
          Talent Empowerment</p>
          <p className="mt-3 lg:mt-0 text-[16px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-9/12">Our talent empowerment services are dedicated to aligning an organization’s workforce with the skills necessary to fully harness AI and data.</p>
        </div>
      </div>

      <ServiceAbout
        image={service}
        contentText1="Our talent services are dedicated to aligning an organization’s workforce with the skills necessary to fully harness AI and data. We believe that the success of AI initiatives heavily depends on the proficiency of the people who use these technologies."
        contentText2="To maximize the return on AI investments, we offer customized training programs, workshops, and hands-on sessions aimed at upskilling employees and fostering a culture of continuous learning. Additionally, we help establish internal AI centers of excellence, ensuring that organizations not only adopt AI but continue to innovate and grow with it. "

      />

      <Data repeat={repeat} />


      {/* <Last /> */}
      <Footer />
    </main>
  );
}

export default Talent;
