import React from "react";

function ServiceAbout({ image, contentText1, contentText2 }) {
  return (
    <main id="about">
      <div className="w-full py-0 lg:py-16 lg:pb-4 pb-10 lg:pb-0 ">
        {/* about-us--- */}
        <div className="max-w-screen-2xl w-full mx-auto px-4 my-14 2xl:my-0">
          <div className="w-full gap-y-10 ps-0 lg:px-28 2xl:px-36 flex items-center justify-around flex-wrap lg:flex-nowrap">
            <div data-aos="fade-left" className="lg:w-[40%] 2xl:w-[40%] w-full flex justify-center ">
              <img alt="service" src={image} />
            </div>

            <div data-aos="fade-right" className="lg:w-[80%] 2xl:w-[60%] w-full">
              <div className="font-inter-serif lg:text-start text-center">
                <p className="leading-[29px]">{contentText1}</p>
                <p className="leading-[29px]">{contentText2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ServiceAbout;
