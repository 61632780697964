import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import serviceImage from "../assets/banner/ABOUT.png";
import AboutExp from "../components/AboutExp";
import Mission from "../components/Mission";
import OurExp from "../components/OurExp";
import Team from "../components/Team";



function About() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const paragraphs = [
    "SentientAI is at the forefront of artificial intelligence innovation, dedicated to developing advanced technologies that empower businesses to achieve more.",

  ];


  return (
    <main className=" ">
      <Navbar />
      <Banner
        bgImage={serviceImage}
        paragraphs={paragraphs} />
      <AboutExp />
      <Mission />
      <OurExp />
      <Team />
      {/* <Last /> */}



      <Footer />
    </main>
  );
}

export default About;
