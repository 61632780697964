import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/1.png";
import img2 from "../assets/vector/2.png";
import img3 from "../assets/vector/3.png";
import img4 from "../assets/vector/4.png";
import img5 from "../assets/vector/5.png";
import img6 from "../assets/vector/6.png";
import service from'../assets/media/AI-Products.png';
import ServiceAbout from "../components/ServiceAbout";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";



function AIimplement() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const navigate = useNavigate();

  const repeat = [
    {
      img: img1,
      title: "Conversational ",
      title2: " Agents",
      description:
        "Our AI-powered conversational agents deliver seamless, human-like interactions, enhancing customer support and engagement with natural language understanding.",
    },
    {
      img: img2,
      title: "Text ",
      title2: "Analytics",
      description:
        "We transform unstructured text into actionable insights, uncovering trends and sentiment to support strategic decision-making across various domains. ",
    },
    {
      img: img3,
      title: "Prediction ",
      title2: " Prescription",
      description:
        "Our predictive and prescriptive analytics models anticipate future outcomes and recommend actions, enabling proactive, data-driven decisions.",
    },
    {
      img: img4,
      title: "Recommendation ",
      title2: "Engine",
      description:
        "Our recommendation engines personalize user experiences by predicting preferences, boosting engagement and conversions through tailored suggestions.",
    },
   
    {
      img: img5,
      title: "Translation ",
      title2: " Transcription",
      description:
        "We provide real-time translation and transcription services powered by AI, enabling seamless communication across languages and improving accessibility.",
    },
    {
      img: img6,
      title: "Image & Video ",
      title2: " Analytics",
      description:
        "Our image and video analytics solutions extract valuable insights from visual content, empowering applications in security, marketing, and more.",
    },
   
  ];

 

  return (
    <main>
      <Navbar />

      <div  className="max-w-screen-2xl w-full mx-auto pt-24 mb-3 px-4 lg:px-10">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <h1 className="text-[16px] lg:text-[24px] font-inter-serif">Back</h1>
        </div>
      </div>

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-10 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div data-aos="fade-right" className="max-w-screen-2xl mx-auto w-full mb-6  font-inter-serif lg:px-16  text-white">
          <p className="pt-20 lg:pt-20 2xl:pt-32 font-semibold text-[36px] lg:text-[48px] lg:text-start text-center">
            AI Products</p>
          <p className="mt-3 lg:mt-0 text-[16px] lg:text-[24px] font-light flex lg:justify-start justify-center lg:text-start text-center leading-[27px]  w-full lg:w-8/12">Revolutionizing industries with cutting-edge AI products designed for impact and efficiency.</p>
        </div>
      </div>

      <ServiceAbout 
       image={service}
       contentText1="At SentientAI, we develop innovative AI products tailored to enhance revenues, improve customer experiences, increase operational efficiency, and manage risk more effectively. We focus on creating custom AI products that address the unique needs of individual businesses, offering a significant competitive advantage."
      
       />

      <Data repeat={repeat} />
      <Footer />
    </main>
  );
}

export default AIimplement;
