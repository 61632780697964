import React from 'react';
import { NavLink } from 'react-router-dom';

function Field() {
  const repeat = [
    {
      path: "/data-ai-strategy",
      title: "Data & AI Strategy",
      description: "Build strategies for data governance and organizational insights."
    },
    {
      path: "/data-ai-implementation",
      title: "Data & AI Implementation ",
      description: "Transform data into AI-powered solutions for improved efficiency and growth."
    },
    {
      path: "/ai-product",
      title: "AI Products",
      description: "Develop AI tools for smarter business and insights."
    },
    {
      path: "/managed-service",
      title: "Managed Services",
      description: "Deliver monitoring, maintenance, and support for opera."
    },
    {
      path: "/digital-transformation",
      title: "Digital Transformation",
      description: "Drive process automation and cybersecurity for growth."
    },
    {
      path: "/talent-empowerment",
      title: "Talent Empowerment",
      description: "Upskill teams with advanced AI training programs."
    },
    // {
    //   path: "/blockChain",
    //   title: "Blockchain",
    //   description: "Blockchain enhances security, transparency, and efficiency."
    // },

  ];

  return (
    <main data-aos="fade-up" className=' py-2 lg:py- max-w-screen-2xl w-full mx-auto  '>
      <div
        data-scroll
        data-scroll-speed="-.5"
        className="lg:flex justify-center   "
      >
        <div className="text-center ">

          <h1 data-aos="fade-up" className="px-2 font-semibold  text-[36px] lg:text-[48px] sm:text-[64px]  leading-[39px] sm:leading-[63px] mb-10 font-inter-serif">
            Services
            <span className="font-semibold bg-gradient-to-r from-[#3DB7B7] to-[#25537E] bg-clip-text text-transparent "> We Offer</span>{" "}
          </h1>
        </div>


      </div>
      <div className='w-full flex justify-center items-center flex-wrap gap-5 px-4 lg:px-0 my-10'>
        {repeat &&
          repeat.map((item) => (
            <NavLink to={item.path} className=' font-inter-serif w-full lg:w-[30%] h-56 pt-12 px-4 lg:px-2 border rounded-lg text-center border-[#D6D6D6]' >
              <div>
                {/* <p className='text-[#7D7D7D]'>{item.no}</p> */}
                <h1 className='text-[24px] font-semibold'>{item.title}</h1>
              </div>

              <div className='mt-4'>
                <p className='lg:text-[20px] text-sm leading-[29px] font-light'>{item.description}</p>
              </div>
            </NavLink>
          ))}
      </div>
    </main>


  );
}

export default Field;
