import React from "react";

function Data({ repeat,description }) {
  return (

    <main className=" ">
      
      <div className="2xl:pt-16 lg:mb-10 mb-10 px-10 lg:px-40 2xl:px-64 max-w-screen-2xl mx-auto w-full  ">
      <p className="text-[16px] lg:text-[24px] text-start lg:text-center font-inter-serif leading-[30px]">
          {description}
        </p>
      </div>

      <div className=" p-10 lg:p-20 bg-[#FAFAFA] -mt-[4rem]">
        <div
          data-aos="fade-up"
          className="w-full max-w-screen-2xl mx-auto w-full flex flex-wrap justify-center gap-8 items-center "
        >
          {repeat &&
            repeat.map((item, index) => (
              <div key={index} className="p-4 py-8 2xl:py-10 lg:p-8 bg-[#25537E0D] rounded-lg w-full 
                2xl:w-[25vw] lg:w-[35vw] min-h-80 md:h-80 lg:h-80 2xl:h-96">
                <div className="text-center">
                  <div className=" flex justify-center items-center ">
                    <img src={item.img} alt={item.title} />
                  </div>

                  <h1 className="mt-2 text-[18px] lg:text-[24px]  font-inter-serif font-semibold leading-[32px]">
                    {item.title}</h1>
                  <h1 className="text-[18px] lg:text-[24px]  font-inter-serif font-semibold leading-[32px]">{item.title2}</h1>
                </div>

                <div className="my-6">
                  <p className="md:text-[16px] font-inter-serif text-sm text-justify leading-[24px]">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
   
    </main>
  );
}

export default Data;
